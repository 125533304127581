export enum ESubscriptionStatus {
    ACTIVE = "active",
    PAST_DUE = "past_due",
    UNPAID = "unpaid",
    CANCELED = "canceled",
    INCOMPLETE = "incomplete",
    INCOMPLETE_EXPIRED = "incomplete_expired",
    TRIALING = "trialing",
}

export enum EPlatformSubscriptionStatus {
    ACTIVE = "active",
    TRIAL = "trialing",
    PAST_DUE = "past_due",
    FAILED = "failed",
    CANCELLED = "cancelled",
    UPGRADED = "upgraded",
    SCHEDULED_DOWNGRADE = "scheduled_downgrade",
    DOWNGRADED = "downgraded",
}

export const ACTIVE_SUBSCRIPTION_STATUSES: ESubscriptionStatus[] = [
    ESubscriptionStatus.ACTIVE,
    ESubscriptionStatus.INCOMPLETE,
    ESubscriptionStatus.TRIALING,
    ESubscriptionStatus.PAST_DUE,
]

export const ACTIVE_PLATFORM_SUBSCRIPTION_STATUSES: EPlatformSubscriptionStatus[] = [
    EPlatformSubscriptionStatus.TRIAL,
    EPlatformSubscriptionStatus.ACTIVE,
    EPlatformSubscriptionStatus.PAST_DUE
]

export enum EPlatformSubscriptionPlan {
    Main = "main",
    Snippets = "snippets",
}

export enum EPlatformSubscriptionPlanFrequency {
    MONTH = "month",
    YEAR = "year",
}

export enum EPlatformSubscriptionType {
    MAIN_STARTER = "main - starter",
    MAIN_PRO = "main - pro",
    SNIPPETS_STARTER = "snippets - starter",
    SNIPPETS_PRO = "snippets - pro",
}

export enum EPlatformSubscriptionUpdateType {
    UPGRADE = "upgrade",
    DOWNGRADE = "downgrade",
    CANCEL = "cancel",
}

export enum ETrialType {
    ThreeDays = "3_days",
    Regular = "7_days",
    NoTrial = "no_trial",
}
