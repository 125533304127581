import { Avatar, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from "@chakra-ui/react"
import { getAuth, signOut } from "firebase/auth"
import { useRouter } from "next/router"
import { useMemo } from "react"

import { AE } from "../@types/analytics"
import { ESettingsPage } from "../@types/global_types"
import _c from "../configs/constants"
import type { BaseUser } from "../server/dao/user_dao"
import api from "../services/root_service"
import {
    linkToCentralRedirect,
    linkToCreateSnippets,
    linkToEditBrands,
    linkToSettingsPage,
    linkToSnippetProjects,
} from "../utils/link_util"
import { hasSnippetPlatformSubscription } from "../utils/user_util"
import { TagLabel } from "./TagLabel"

export interface INavbarProps {
    currentUser: BaseUser
}

export const AI_PAGES = [
    "/clips",
    "/clips/projects",
    "/clips/editor",
    "/clips/brands/edit",
    "/clips/pricing",
    "/account/subscriptions/cancel",
]

const UserNavbarMenu = ({ currentUser }: INavbarProps) => {
    const router = useRouter()
    const currentPage = router.pathname
    const userHasPlatformSubscription = hasSnippetPlatformSubscription(currentUser)

    const isSnippetMode = useMemo(
        () => AI_PAGES.some((page) => currentPage.includes(page)),
        [currentPage],
    )

    const handleLogout = async () => {
        try {
            await api().postLogout()
            await signOut(getAuth())
            router.push(isSnippetMode ? "/clips" : "/")
        } catch (e: any) {}
    }

    const handleRoute =
        (url: string, title: string, newTab = false) =>
        async () => {
            const Analytics = (await import("../controllers/analytics_controller")).default
            Analytics.trackEvent(AE.Navbar_Click, { title, url, newTab })
            if (newTab) {
                window.open(url, "_blank")?.focus()
            } else {
                router.push(url)
            }
        }

    const handleSwitchToCreator = () => {
        if (currentUser.isCreator) {
            router.push(linkToCentralRedirect())
        } else {
            router.push("/signup/activate-creatorship")
        }
    }

    const renderToggleProfile = () => {
        if (!currentUser.isCreator || currentPage === "/feed") {
            return (
                <MenuItem
                    onClick={handleSwitchToCreator}
                    closeOnSelect={true}
                    paddingX={5}
                    paddingY={2}
                >
                    Switch to creator
                </MenuItem>
            )
        } else {
            return (
                <MenuItem
                    onClick={() => router.push("/feed")}
                    closeOnSelect={true}
                    paddingX={5}
                    paddingY={2}
                >
                    Switch to learner
                </MenuItem>
            )
        }
    }

    const handleBookCall = async () => {
        const Analytics = (await import("../controllers/analytics_controller")).default
        const AlertController = (await import("../controllers/alert_controller")).default
        Analytics.trackEvent(AE.BookCall_Click)
        AlertController.show({
            title: "Looking for expert advice?",
            message:
                "Hop on a brief call with Ismail and he'll show you the most profitable ways to monetize your content.",
            primaryAction: {
                label: "Book call",
                onClick: handleRoute("https://calendly.com/ismailjeilani", "Book a call", true),
            },
            isDismissable: true,
            size: "md",
            icon: (
                <Avatar
                    src="/images/ismail_bookcall.png"
                    boxSize={{ base: "55px", md: "67px" }}
                    bg="gray.300"
                    color="gray.700"
                    alignSelf="start"
                    mb={2}
                />
            ),
        })
    }

    if (isSnippetMode) {
        return (
            <Menu eventListeners={true} autoSelect={false} gutter={4} placement="bottom-end">
                <MenuButton>
                    <Avatar
                        src={currentUser.picture ?? undefined}
                        name={currentUser.slug ?? undefined}
                        justifyContent="center"
                        boxSize={["55px", null, "45px"]}
                        borderWidth={[3, 2]}
                        borderColor="white"
                        borderStyle="solid"
                        cursor="pointer"
                        bg="gray.300"
                        color="gray.700"
                    />
                </MenuButton>
                <MenuList
                    borderRadius="10px"
                    borderColor="#E5E5E5"
                    boxShadow="0px 12px 22px #00000029"
                    position="relative"
                >
                    <MenuItem
                        onClick={handleRoute(linkToSnippetProjects(), "My projects")}
                        closeOnSelect={true}
                        fontWeight="semibold"
                        paddingX={5}
                        paddingY={2}
                    >
                        My projects
                    </MenuItem>
                    <MenuItem
                        onClick={handleRoute(linkToEditBrands(), "Brand templates")}
                        closeOnSelect={true}
                        fontWeight="semibold"
                        paddingX={5}
                        paddingY={2}
                    >
                        Brand templates
                    </MenuItem>
                    <MenuItem
                        onClick={handleRoute("/clips/pricing", "Pricing")}
                        closeOnSelect={true}
                        fontWeight="semibold"
                        paddingX={5}
                        paddingY={2}
                    >
                        {userHasPlatformSubscription ? "Manage Plan" : "Pricing Plans"}
                    </MenuItem>
                    <MenuDivider />
                    {renderToggleProfile()}
                    <MenuItem
                        as="a"
                        href="mailto:support@livelink.vip"
                        closeOnSelect={true}
                        paddingX={5}
                        paddingY={2}
                    >
                        Support
                    </MenuItem>
                    <MenuItem
                        closeOnSelect={true}
                        paddingX={5}
                        paddingY={2}
                        fontWeight="semibold"
                        color="red.500"
                        onClick={handleLogout}
                    >
                        Logout
                    </MenuItem>
                </MenuList>
            </Menu>
        )
    }

    return (
        <Menu eventListeners={true} autoSelect={false} gutter={4} placement="bottom-end">
            <MenuButton>
                <Avatar
                    src={currentUser.picture ?? undefined}
                    name={currentUser.slug ?? undefined}
                    justifyContent="center"
                    boxSize={["55px", null, "45px"]}
                    borderWidth={[3, 2]}
                    borderColor="white"
                    borderStyle="solid"
                    cursor="pointer"
                    bg="gray.300"
                    color="gray.700"
                />
            </MenuButton>
            <MenuList
                borderRadius="10px"
                borderColor="#E5E5E5"
                boxShadow="0px 12px 22px #00000029"
                position="relative"
            >
                {!currentUser.isCreator && (
                    <MenuItem
                        onClick={handleRoute("/feed", "Home")}
                        closeOnSelect={true}
                        paddingX={5}
                        paddingY={2}
                        fontWeight="semibold"
                    >
                        Home
                    </MenuItem>
                )}
                {currentUser.isCreator && (
                    <>
                        <MenuItem
                            onClick={handleRoute("/", "Dashboard")}
                            closeOnSelect={true}
                            paddingX={5}
                            paddingY={2}
                            fontWeight="semibold"
                        >
                            Dashboard
                        </MenuItem>
                        <MenuItem
                            onClick={handleRoute(
                                `/${currentUser.slug}?mode=preview`,
                                "Preview LiveLink Profile",
                            )}
                            closeOnSelect={true}
                            paddingX={5}
                            paddingY={2}
                            fontWeight="semibold"
                        >
                            Preview Profile
                        </MenuItem>
                        <MenuItem
                            onClick={handleRoute(`/members`, "Members")}
                            closeOnSelect={true}
                            paddingX={5}
                            paddingY={2}
                            fontWeight="semibold"
                        >
                            Members
                        </MenuItem>
                        <MenuItem
                            onClick={handleRoute(`/${currentUser.slug}/community`, "Community")}
                            closeOnSelect={true}
                            paddingX={5}
                            paddingY={2}
                            fontWeight="semibold"
                        >
                            Community
                        </MenuItem>
                        <MenuItem
                            onClick={handleRoute("/account/payments", "Payments", true)}
                            closeOnSelect={true}
                            paddingX={5}
                            paddingY={2}
                            fontWeight="semibold"
                        >
                            Payments
                        </MenuItem>
                        <MenuItem
                            onClick={handleRoute(
                                linkToSettingsPage(ESettingsPage.Profile),
                                "Settings",
                            )}
                            closeOnSelect={true}
                            paddingX={5}
                            paddingY={2}
                            fontWeight="semibold"
                        >
                            Settings
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem
                            onClick={handleRoute("/refer-a-creator", "Refer a creator")}
                            closeOnSelect={true}
                            paddingX={5}
                            paddingY={2}
                        >
                            Refer a creator
                        </MenuItem>
                        <MenuItem
                            onClick={handleBookCall}
                            closeOnSelect={true}
                            paddingX={5}
                            paddingY={2}
                        >
                            Book a call
                        </MenuItem>
                    </>
                )}
                {renderToggleProfile()}
                <MenuItem
                    onClick={handleRoute(linkToCreateSnippets(), "AI clip tool")}
                    closeOnSelect={true}
                    paddingX={5}
                    paddingY={2}
                >
                    AI clip tool
                    <TagLabel category="new" />
                </MenuItem>

                {!currentUser.isCreator && <MenuDivider />}
                <MenuItem
                    as="a"
                    href="mailto:support@livelink.vip"
                    closeOnSelect={true}
                    paddingX={5}
                    paddingY={2}
                >
                    Support
                </MenuItem>
                <MenuItem
                    closeOnSelect={true}
                    paddingX={5}
                    paddingY={2}
                    fontWeight="semibold"
                    color="red.500"
                    onClick={handleLogout}
                >
                    Logout
                </MenuItem>
            </MenuList>
        </Menu>
    )
}

export default UserNavbarMenu
